import React from "react";
import { ChevronDown } from "react-feather";
import { classList } from "../../lib/helpers";
import * as styles from "./PopoverMenu.module.scss";

export interface PopoverMenuProps {
  children?: React.ReactNode;
  triggerElement?: React.ReactNode;
  className?: React.ReactNode;
}

export interface PopoverMenuItemProps {
  title?: string;
  icon?: React.ReactNode;
}

export const PopoverMenu: React.FC<PopoverMenuProps> = ({
  children,
  triggerElement,
  className,
}) => {
  const classes = classList(styles.popoverMenu, className && className);
  return (
    <div className={classes}>
      <button aria-haspopup="true">
        <div>{triggerElement}</div>
        <i>
          <ChevronDown />
        </i>
      </button>
      <div className={styles.popoverMenu__popupContainer}>
        <div className={styles.popoverMenu__popup}>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export const PopoverMenuItem: React.FC<PopoverMenuItemProps> = ({
  title,
  icon,
}) => {
  return (
    <div className={styles.popoverMenuItem}>
      <i>{icon}</i>
      <span>{title}</span>
    </div>
  );
};
