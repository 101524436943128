import React from "react";
import { Download } from "react-feather";
import * as styles from "./DownloadLink.module.scss";
import { getFileAsset } from "@sanity/asset-utils";
import clientConfig from "../../../client-config";
import { useFetchDocumentByReference } from "../../pages/preview";

export type DownloadData = {
  _ref?: string;
  file?: { asset: any };
};

export interface DownloadLinkProps {
  data?: DownloadData;
  children: React.ReactNode;
}

export const DownloadLink: React.FC<DownloadLinkProps> = ({
  data,
  children,
}) => {
  const documentByReference = useFetchDocumentByReference(data?._ref);

  const downloadDocument = data?.file ? data : documentByReference; // for preview render

  const asset = downloadDocument?.file?.asset.url
    ? downloadDocument?.file?.asset
    : downloadDocument &&
      getFileAsset(downloadDocument?.file?.asset, clientConfig.sanity); // for previews render

  const downloadUrl = asset?.url || "#";
  const fileExt = asset?.extension || "[NO FILE FOUND]";
  return (
    <a
      className={styles.downloadLink}
      href={`${downloadUrl}?dl=`}
      target="_blank"
      rel="noreferrer"
    >
      <span>{children}</span>
      <span className={styles.downloadLink__fileType}>
        <i>
          <Download />
        </i>
        {fileExt}
      </span>
    </a>
  );
};
