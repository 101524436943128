import React from "react";

import { classList } from "../../lib/helpers";
import * as styles from "./Grid.module.scss";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

export interface GridProps {
  className?: string;
  flipMobileDirection?: boolean;
  children: React.ReactNode;
  alignment?: string;
  gutterSize?: "default" | "none" | "small";
}
export interface GridItemProps {
  className?: string;
  children: React.ReactNode;
  width: number;
}

export const Grid: React.FC<GridProps> = ({
  className,
  children,
  alignment = "center",
  flipMobileDirection = false,
  gutterSize = "default",
}) => {
  const classes = classList(
    styles.grid,
    className && className,
    flipMobileDirection && styles.grid_flipMobileDirection,
    styles["grid_gutterSize_" + gutterSize],
    styles["grid_alignment_" + alignment]
  );
  return <div className={classes}>{children}</div>;
};

export const GridItem: React.FC<GridItemProps> = ({
  className,
  children,
  width,
}) => {
  const classes = classList(styles.grid__item, className && className);

  const responsiveWidth = useBreakpoint().md ? "100" : width;

  return (
    <div style={{ width: `${responsiveWidth}%` }} className={classes}>
      {children}
    </div>
  );
};
