import * as styles from "./Sponsors.module.scss";

import { Link, LinkData } from "../Link/Link";
import React from "react";
import { Section } from "../Section/Section";
import { Grid, GridItem } from "../Grid/Grid";
import { SectionSettingsData } from "../Section/Section";
import { MainImage } from "../MainImage/MainImage";
import { classList } from "../../lib/helpers";

export type SponsorsData = {
  sponsorEntries: SponsorsEntryData[];
  variant?: "default" | "box";
  sectionSettings: SectionSettingsData;
};

type SponsorsEntryData = {
  link?: LinkData;
  logo?: any;
  _key?: string;
  variant?: "default" | "box";
};

interface SponsorsProps {
  data: SponsorsData;
}

export const Sponsors: React.FC<SponsorsProps> = ({ data }) => {
  const widthPct = Math.round(100 / data?.sponsorEntries?.length);
  return (
    <Section {...data?.sectionSettings}>
      <Grid alignment={"stretch"} className={styles.sponsors}>
        {data?.sponsorEntries?.map((item) => {
          return (
            <GridItem key={item._key} width={widthPct}>
              <SponsorsEntry {...item} variant={data.variant} />
            </GridItem>
          );
        })}
      </Grid>
    </Section>
  );
};

const SponsorsEntry: React.FC<SponsorsEntryData> = ({
  link,
  logo,
  variant = "default",
}) => {
  return (
    <Link
      className={classList(
        styles.sponsorEntry,
        styles[`sponsorEntry_${variant}`]
      )}
      to={link}
    >
      <MainImage className={styles.sponsorEntry__logo} imageData={logo} />
    </Link>
  );
};
