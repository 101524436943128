import React from "react";

import ClassPadLabel from "../../assets/svg/casio-education-logo-grey.svg";
import { BlockContent } from "../BlockContent/BlockContent";
import { Link, LinkData } from "../Link/Link";
import * as styles from "./Footer.module.scss";

export type FooterData = {
  metaNavigation: LinkData[];
  _rawContent?: any;
  content?: any;
};

interface FooterProps {
  data?: FooterData;
  language: string;
}

export const Footer: React.FC<FooterProps> = ({ data, language }) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__contact}>
        <ClassPadLabel className={styles.footer__contact__logo} />
        <BlockContent
          blocks={data?._rawContent || data?.content}
          language={language}
          nested
        />
      </div>
      <nav className={styles.footer__navigation}>
        <ul>
          {data?.metaNavigation?.map((item, index) => {
            return (
              <li key={index}>
                <Link to={item}></Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className={styles.footer__copyright}>
        &copy; {new Date().getFullYear()} Casio Computer Co., LTD.
      </div>
    </footer>
  );
};
