import React from "react";

import { classList } from "../../lib/helpers";
import { Section, SectionSpace, SectionWidth } from "../Section/Section";
import * as styles from "./Banner.module.scss";
import { MainImage } from "../MainImage/MainImage";

export type BannerBackgroundType = React.ReactElement | "primary";
export type BannerTitleType = React.ReactElement | string;

export type BannerData = {
  backgroundImage?: any;
  logoImage?: any;
  title?: string;
};

export interface BannerProps {
  className?: string;
  title?: BannerTitleType;
  data: BannerData;
}

export const Banner: React.FC<BannerProps> = ({ className, title, data }) => {
  const classes = classList(
    styles.banner,
    styles.banner_gradient,
    className && className
  );

  const withLogo = data?.logoImage?.asset;

  return (
    <Section
      width={SectionWidth.FULL}
      spacingTop={SectionSpace.NONE}
      spacingBottom={SectionSpace.NONE}
      className={styles.bannerSection}
    >
      <div className={classes}>
        <div className={styles.banner__image}>
          <MainImage imageData={data?.backgroundImage} />
        </div>
        <div
          className={classList(
            styles.banner__content,
            withLogo && styles.banner__content_withLogo
          )}
        >
          {withLogo && (
            <MainImage
              imageData={data?.logoImage}
              className={styles.banner__content__logo}
            />
          )}
          {data?.title && (
            <h1 className={styles.banner__content__title}>{data?.title}</h1>
          )}
        </div>
      </div>
    </Section>
  );
};
