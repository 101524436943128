import * as styles from "./MainNavigation.module.scss";
import { getBasePathForLanguage } from "../../../sanity/plugins/multilanguage";

import { LogIn, Menu, UserPlus, X } from "react-feather";
import React, { useState } from "react";
import { LanguageSwitch } from "../LanguageSwitch/LanguageSwitch";

import { classList } from "../../lib/helpers";

export interface MainNavigationProps {
  children?: React.ReactNode;
  registerCta?: string;
  loginCta?: string;
  language: string;
  availableLanguages?: string[];
}

export const MainNavigation: React.FC<MainNavigationProps> = ({
  children,
  registerCta,
  loginCta,
  language,
  availableLanguages,
}) => {
  const [toggled, setToggled] = useState(false);
  const classes = classList(
    styles.mainNavigation,
    toggled && styles.mainNavigation_toggled
  );
  return (
    <nav className={classes}>
      <NavAction
        icon={<LogIn />}
        href="/app"
        label={loginCta || "Login"}
        type="primary"
        className={styles.mainNavigation__login}
      />
      {availableLanguages && availableLanguages?.length >= 2 && (
        <LanguageSwitch
          currentLanguage={language}
          availableLanguages={availableLanguages}
          className={styles.mainNavigation__languageSwitch}
        />
      )}
      <button onClick={() => setToggled(!toggled)}>
        <i>
          <Menu />
          <X />
        </i>
      </button>
      <div
        className={styles.mainNavigation__backdrop}
        onClick={() => setToggled(!toggled)}
      ></div>
      <ul>
        <div className={styles.mainNavigation__links}>{children}</div>
        <div className={styles.mainNavigation__actions}>
          <li>
            <NavAction
              icon={<LogIn />}
              href={getBasePathForLanguage(language) + "app"}
              label={loginCta || "Login"}
              type="primary"
            />
          </li>
          <li className={styles.mainNavigation__actions__registrationCta}>
            <NavAction
              icon={<UserPlus />}
              href={getBasePathForLanguage(language) + "app/registration"}
              label={registerCta || "Register"}
              type="secondary"
            />
          </li>
        </div>
      </ul>
    </nav>
  );
};

export const NavAction: React.FC<{
  type?: "primary" | "secondary";
  icon?: React.ReactNode;
  href: string;
  label: string;
  className?: string;
}> = ({ type = "primary", icon, href, label, className }) => {
  const classes = classList(
    styles.navAction,
    styles["navAction_" + type],
    className && className
  );
  return (
    <a href={href} className={classes}>
      <i>{icon}</i>
      <span>{label}</span>
    </a>
  );
};
