import React from "react";
import * as SanityBlockContent from "@sanity/block-content-to-react";

import { classList } from "../../lib/helpers";
import * as styles from "./BlockContent.module.scss";
import { Link } from "../Link/Link";
import { Button } from "../Button/Button";
import { Banner } from "../Banner/Banner";
import { PostList } from "../PostList/PostList";
import { NewsBox } from "../NewsBox/NewsBox";
import { Columns } from "../Columns/Columns";
import { TabletScreen } from "../TabletScreen/TabletScreen";
import { ServiceOverview } from "../ServiceOverview/ServiceOverview";
import { Sponsors } from "../Sponsors/Sponsors";
import { ImageBlock } from "../ImageBlock/ImageBlock";
import { MainImage } from "../MainImage/MainImage";
import { PricesSlider } from "../PricesSlider/PricesSlider";
import { ChevronList } from "../ChevronList/ChevronList";
import { PricesOverview } from "../PricesOverview/PricesOverview";
import { Collapsables } from "../Collapsables/Collapsables";
import { Box } from "../Box/Box";
import { Table } from "../Table/Table";
import { DownloadLink } from "../DownloadLink/DownloadLink";
import { AnnouncementBanner } from "../AnnouncementBanner/AnnouncementBanner";
import { CustomCode } from "../CustomCode/CustomCode";

export interface BlockContentProps {
  blocks?: any;
  language: string;
  nested?: boolean;
  className?: string;
}

export const BlockContent: React.FC<BlockContentProps> = ({
  blocks,
  className,
  language,
  nested = false,
}) => {
  if (!blocks) return null;
  const serializerTypes = {
    ...blockContentSerializers.types,
    banner({ node: bannerData }) {
      return <Banner data={bannerData} />;
    },
    announcementBanner({ node: announcementBannerData }) {
      return <AnnouncementBanner data={announcementBannerData} />;
    },
    postList() {
      return <PostList language={language} />;
    },
    newsBox({ node: newsBoxData }) {
      return <NewsBox data={newsBoxData} language={language} />;
    },
    columns({ node: columnsData }) {
      return <Columns data={columnsData} language={language} />;
    },
    tabletScreen({ node: tabletScreenData }) {
      return <TabletScreen data={tabletScreenData} language={language} />;
    },
    serviceOverview({ node: serviceOverviewData }) {
      return <ServiceOverview data={serviceOverviewData} />;
    },
    sponsors({ node: sponsorsData }) {
      return <Sponsors data={sponsorsData} />;
    },
    imageBlock({ node: imageBlockData }) {
      return <ImageBlock data={imageBlockData} />;
    },
    mainImage({ node: mainImageData }) {
      return <MainImage imageData={mainImageData} />;
    },
    pricesSlider({ node: pricesSliderData }) {
      return <PricesSlider data={pricesSliderData} />;
    },
    pricesOverview({ node: pricesOverviewData }) {
      return <PricesOverview data={pricesOverviewData} language={language} />;
    },
    collapsables({ node: collapsablesData }) {
      return <Collapsables data={collapsablesData} language={language} />;
    },
    box({ node: boxData }) {
      return <Box data={boxData} language={language} />;
    },
    table({ node: tableData }) {
      return <Table data={tableData} language={language} />;
    },
    customCode({ node: customCodeData }) {
      return <CustomCode data={customCodeData} language={language} />;
    },
    anchorTarget({ node: anchorTargetData }) {
      return <div id={anchorTargetData.anchorId}></div>;
    },
  };

  // filter out unknown block types so the app doesn't crash if there is one
  const knownBlocks = blocks?.filter((item) => {
    return serializerTypes.hasOwnProperty(item._type);
  });

  const classes = classList(
    styles.blockContent,
    nested && styles.blockContent_nested,
    className && className
  );
  return (
    <SanityBlockContent
      className={classes}
      renderContainerOnSingleChild={true}
      blocks={knownBlocks}
      serializers={{
        types: serializerTypes,
        marks: blockContentSerializers.marks,
        list: blockContentSerializers.list,
      }}
    ></SanityBlockContent>
  );
};

function hasMark(markName: string, props: any) {
  const found = props?.node?.children?.find((element: any) =>
    element?.marks?.includes(markName)
  );
  return found !== undefined;
}

function haveChildrenMark(markName: string, children: any) {
  const found = children?.find((item: any) => hasMark(markName, item?.props));
  return found !== undefined;
}

const blockContentSerializers = {
  types: {
    blockContent(props: any) {
      /*
       * Check marks manually...
       */
      const classes = classList(
        hasMark("narrow", props) && styles.narrow,
        hasMark("alignCenter", props) && styles.alignCenter
      );

      switch (props.node.style) {
        case "h1":
          return <h1 className={classes}>{props.children}</h1>;
        case "h2":
          return <h2 className={classes}>{props.children}</h2>;
        case "h3":
          return <h3 className={classes}>{props.children}</h3>;
        case "h4":
          return <h4 className={classes}>{props.children}</h4>;
        case "normal":
          return <p className={classes}>{props.children}</p>;
        default:
          return SanityBlockContent.defaultSerializers.types.block(props);
      }
    },
  },
  list: (props: any) => {
    const classes = classList(
      haveChildrenMark("narrow", props.children) && styles.narrow
    );

    switch (props.type) {
      case "bullet":
        return (
          <List className={classes} listItems={props.children} type="bullet" />
        );
      case "number":
        return (
          <List className={classes} listItems={props.children} type="number" />
        );
      case "chevron":
        return <ChevronList className={classes} listItems={props.children} />;
      default:
        return SanityBlockContent.defaultSerializers.list(props);
    }
  },
  marks: {
    narrow(props: any) {
      return props.children;
    },
    alignCenter(props: any) {
      return props.children;
    },
    undefined(props: any) {
      return props.children;
    },
    nohyphen(props: any) {
      return <span className={styles.nohyphen}>{props.children}</span>;
    },
    link(props: any) {
      const isButton = props.mark.style && props.mark.style === "button";
      return (
        <Link to={props.mark}>
          {isButton ? <Button label={props.children} /> : props.children}
        </Link>
      );
    },
    downloadLink(props: any) {
      return (
        <DownloadLink data={props?.mark?.download}>
          {props.children}
        </DownloadLink>
      );
    },
  },
};

interface ListProps {
  listItems: React.ReactNode[];
  type?: "bullet" | "number";
  className?: string;
}

const List: React.FC<ListProps> = ({
  listItems,
  type = "bullet",
  className,
}) => {
  const listItemMap = listItems?.map((item: any) => {
    return <li key={item?.key}>{item?.props?.children}</li>;
  });
  switch (type) {
    case "bullet":
      return <ul className={className && className}>{listItemMap}</ul>;
    case "number":
      return <ol className={className && className}>{listItemMap}</ol>;
    default:
      return <ul className={className && className}>{listItemMap}</ul>;
  }
};
