import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { getBasePathForLanguage } from "../../../sanity/plugins/multilanguage";
import { stripSlashes } from "../../lib/helpers";
import { useFetchDocumentByReference } from "../../pages/preview";

export type LinkData = {
  externalUrl?: string;
  page?: {
    language?: string;
    slug?: { current: string };
    title?: string;
    _ref?: string;
  };
  title?: string;
};

export type ResolvedLinkData = {
  path?: string;
  href?: string;
  title?: string;
  disabled?: boolean;
};
export interface LinkProps {
  to: LinkData;
  children?: React.ReactNode;
  className?: string;
  component?: (link: ResolvedLinkData) => React.ReactNode;
}

export const Link: React.FC<LinkProps> = ({
  to,
  children,
  className,
  component,
}) => {
  const resolvedLink: ResolvedLinkData = useResolveLinkData(to);
  const componentNode = component && component(resolvedLink);

  if (resolvedLink.path) {
    return (
      <GatsbyLink to={resolvedLink.path} className={className}>
        {componentNode || children || resolvedLink.title}
      </GatsbyLink>
    );
  } else {
    const isAnchor = resolvedLink.href?.startsWith("#");
    return (
      <a
        href={resolvedLink.href}
        target={isAnchor ? "" : "_blank"}
        className={className}
        rel="noreferrer"
      >
        {componentNode || children || resolvedLink.title}
      </a>
    );
  }
};

function useResolveLinkData(linkData: LinkData): ResolvedLinkData {
  const externalLink = linkData?.externalUrl;
  const pageReference = linkData?.page;

  const documentByReference = useFetchDocumentByReference(pageReference?._ref);

  if (externalLink) {
    const title = linkData?.title || externalLink;
    return { href: externalLink, title: title };
  } else if (pageReference) {
    /*
     * If this is a link in a preview, we need to fetch the referenced page again,
     * because the preview root data only comes with an id and is unfortunately
     * missing the slug...
     */
    const page = pageReference.slug ? pageReference : documentByReference;
    const slug = page?.slug?.current;
    const target = getBasePathForLanguage(page?.language) + stripSlashes(slug);
    const title = linkData?.title || page?.title || slug;

    return { path: target, title: title };
  } else {
    return { title: linkData?.title, disabled: true };
  }
}
