import React from "react";
import { ChevronRight } from "react-feather";

import { classList } from "../../lib/helpers";
import * as styles from "./ChevronList.module.scss";

export interface ChevronListProps {
  listItems: React.ReactNode[];
  className?: string;
}

export const ChevronList: React.FC<ChevronListProps> = ({
  listItems,
  className,
}) => {
  const classes = classList(styles.chevronList, className && className);
  return (
    <ul className={classes}>
      {listItems?.map((item: any) => {
        return (
          <ChevronListItem key={item?.key}>
            {item?.props.children}
          </ChevronListItem>
        );
      })}
    </ul>
  );
};

export interface ChevronListItemProps {
  children: React.ReactNode;
}

export const ChevronListItem: React.FC<ChevronListItemProps> = ({
  children,
}) => {
  return (
    <li className={styles.chevronList__item}>
      <i className={styles.chevronList__item__bulletIcon}>
        <ChevronRight />
      </i>
      <span>{children}</span>
    </li>
  );
};
