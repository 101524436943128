// extracted by mini-css-extract-plugin
export var navAction = "MainNavigation-module--navAction--MuYyA";
export var navAction_primary = "MainNavigation-module--navAction_primary--aOAoT";
export var navAction_secondary = "MainNavigation-module--navAction_secondary--TCHZd";
export var mainNavigation = "MainNavigation-module--mainNavigation--UZ++i";
export var mainNavigation__login = "MainNavigation-module--mainNavigation__login--UJOmn";
export var mainNavigation__languageSwitch = "MainNavigation-module--mainNavigation__languageSwitch--hzatt";
export var mainNavigation__backdrop = "MainNavigation-module--mainNavigation__backdrop--naT-A";
export var mainNavigation__links = "MainNavigation-module--mainNavigation__links--stzbO";
export var mainNavigation__actions = "MainNavigation-module--mainNavigation__actions--7XsEJ";
export var mainNavigation__actions__registrationCta = "MainNavigation-module--mainNavigation__actions__registrationCta --Aa5HW";
export var mainNavigation_toggled = "MainNavigation-module--mainNavigation_toggled--DJxnf";