import "../assets/_global.scss";

import { Header, HeaderData } from "../components/Header/Header";

import { Footer, FooterData } from "../components/Footer/Footer";
import { Layout } from "../components/Layout/Layout";
import React from "react";
import { SEO, SEOData } from "../components/Seo/Seo";
import { graphql } from "gatsby";

import * as styles from "./page.module.scss";
import { BlockContent } from "../components/BlockContent/BlockContent";

const { stripSlashes } = require("../lib/helpers");
const {
  getBasePathForLanguage,
} = require("../../sanity/plugins/multilanguage");

export const query = graphql`
  query PageQuery($id: String, $language: String) {
    sanityStartPage(id: { eq: $id }) {
      id
      language
      title
      _rawPageContent(resolveReferences: { maxDepth: 10 })
    }
    sanityPage(id: { eq: $id }) {
      id
      language
      title
      pageRedirect {
        redirectTarget {
          ... on SanityStartPage {
            id
            language
            slug {
              current
            }
          }
          ... on SanityPage {
            id
            language
            slug {
              current
            }
          }
          ... on SanityExternalRedirectTarget {
            url
          }
        }
      }
      _rawPageContent(resolveReferences: { maxDepth: 10 })
    }
    sanitySiteSettings(language: { eq: $language }) {
      id
      header {
        registerCta
        loginCta
        navigation {
          externalUrl
          title
          page {
            ... on SanityPage {
              language
              title
              slug {
                current
              }
            }
            ... on SanityStartPage {
              language
              title
              slug {
                current
              }
            }
          }
        }
      }
      footer {
        metaNavigation {
          externalUrl
          title
          page {
            ... on SanityPage {
              title
              language
              slug {
                current
              }
            }
            ... on SanityStartPage {
              title
              language
              slug {
                current
              }
            }
          }
        }
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
      seo {
        description
        title
      }
    }
  }
`;

export type PageData = {
  id: string;
  language: string;
  title: string;
  pageContent?: any;
  pageRedirect?: any;
  _rawPageContent?: any;
};

type QueryData = {
  sanityStartPage?: PageData;
  sanityPage?: PageData;
  sanitySiteSettings?: {
    id: string;
    header: HeaderData;
    footer: FooterData;
    seo: SEOData;
  };
};

interface PageTemplateProps {
  data: QueryData;
  pageContext: {
    language: string;
    id: string;
    availableLanguages?: string[];
  };
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: { sanityPage, sanityStartPage, sanitySiteSettings },
  pageContext: { language, availableLanguages },
}) => {
  const pageData = sanityPage || sanityStartPage;

  const redirectPath = getRedirectPath(pageData?.pageRedirect);
  if (redirectPath) {
    if (typeof window != "undefined") {
      window.location.replace(redirectPath);
    }
  }

  return (
    <Layout>
      <SEO
        pageTitle={pageData?.title}
        data={sanitySiteSettings?.seo}
        language={language}
      />
      <Header
        language={language}
        data={sanitySiteSettings?.header}
        availableLanguages={availableLanguages}
      />
      <main className={styles.main}>
        <BlockContent
          blocks={pageData?._rawPageContent || pageData?.pageContent}
          language={language}
        />
      </main>
      <Footer language={language} data={sanitySiteSettings?.footer} />
    </Layout>
  );
};

const getRedirectPath = (pageRedirect: any) => {
  const redirectItem = pageRedirect?.redirectTarget[0];

  if (!redirectItem) {
    return false;
  } else if (redirectItem?.url) {
    return redirectItem.url;
  } else if (redirectItem?.slug?.current) {
    const path = redirectItem.slug.current;
    return getBasePathForLanguage(redirectItem?.language) + stripSlashes(path);
  } else {
    return false;
  }
};

export default PageTemplate;
