import "../assets/_global.scss";
import React, { useEffect, useState } from "react";
import clientConfig from "../../client-config";
import sanityClient from "@sanity/client";
import { Layout } from "../components/Layout/Layout";
import PageTemplate from "../templates/page";
import { NewsPost } from "../components/PostList/PostList";
import { Section, SectionWidth } from "../components/Section/Section";

const client = sanityClient({
  ...clientConfig.sanity,
  apiVersion: "v1",
  token: "",
  useCdn: false,
});

const Preview: React.FC = () => {
  const [sanityDocumentAndSettings, setSanityDocumentAndSettings] =
    useState(null);

  const queryString =
    typeof window == "undefined" ? "" : window.location.search;
  const documentId =
    queryString && new URLSearchParams(queryString).get("documentId");

  useEffect(() => {
    if (!documentId) {
      return;
    }

    const fetchData = async () => {
      const documents = await client.fetch(
        '*[_id in [$documentId, "drafts." + $documentId]] {..., "siteSettings": *[ _type == "siteSettings" && language == ^.language ]}',
        { documentId: documentId }
      );
      setSanityDocumentAndSettings(
        documents.find(({ _id }) => _id.startsWith("drafts.")) || documents[0]
      );
    };
    fetchData();
  }, [documentId]);

  if (typeof window == "undefined") {
    return null;
  }

  if (!documentId) {
    return <div>This document preview couln't be found...</div>;
  }

  if (documentId.startsWith("drafts.")) {
    return (
      <div>
        Please publish the document and reopen the preview to view your
        changes...
      </div>
    );
  }

  if (!sanityDocumentAndSettings) return null;

  const { siteSettings, ...document } = sanityDocumentAndSettings;

  switch (document._type) {
    case "page":
    case "startPage":
      return (
        <PageTemplate
          data={{
            sanityPage: document,
            sanitySiteSettings: siteSettings[0],
          }}
          pageContext={{
            language: document?.language,
            id: document?.id,
          }}
        />
      );
    case "post":
      return (
        <Layout>
          <Section width={SectionWidth.NARROW}>
            <NewsPost data={sanityDocumentAndSettings!} />
          </Section>
        </Layout>
      );
    default:
      return (
        <Layout>
          <p>No Preview template configured.</p>
        </Layout>
      );
  }
};

export function useFetchDocumentByReference(_ref?: string) {
  const [document, setDocument] = useState(null);

  useEffect(() => {
    if (!_ref) {
      return;
    }

    const fetchReference = async () => {
      const documents = await client.fetch("*[_id == $_refId]", {
        _refId: _ref,
      });
      setDocument(
        documents.find(({ _id }) => _id.startsWith("drafts.")) || documents[0]
      );
    };
    fetchReference();
  }, [_ref]);

  if (!_ref) return null;

  return document;
}

export default Preview;
