import * as styles from "./ServiceOverview.module.scss";

import { Link, LinkData } from "../Link/Link";
import React from "react";
import { Section } from "../Section/Section";
import { Grid, GridItem } from "../Grid/Grid";
import { Button } from "../Button/Button";
import { SectionSettingsData } from "../Section/Section";

export type ServiceOverviewData = {
  serviceOverviewItems: ServiceOverviewItemData[];
  sectionSettings: SectionSettingsData;
};

type ServiceOverviewItemData = {
  link?: LinkData;
  text?: string;
  title?: string;
  _key?: string;
};

interface ServiceOverviewProps {
  data: ServiceOverviewData;
}

export const ServiceOverview: React.FC<ServiceOverviewProps> = ({ data }) => {
  const widthPct = Math.round(100 / data?.serviceOverviewItems?.length);
  return (
    <Section {...data?.sectionSettings}>
      <Grid alignment={"stretch"}>
        {data?.serviceOverviewItems?.map((item) => {
          return (
            <GridItem key={item._key} width={widthPct}>
              <ServiceOverviewItem {...item} />
            </GridItem>
          );
        })}
      </Grid>
    </Section>
  );
};

const ServiceOverviewItem: React.FC<ServiceOverviewItemData> = ({
  title,
  link,
  text,
}) => {
  return (
    <div className={styles.serviceOverviewItem}>
      <div className={styles.serviceOverviewItem__content}>
        <h3 className={styles.serviceOverviewItem__content__title}>{title}</h3>
        <p className={styles.serviceOverviewItem__content__description}>
          {text}
        </p>
        <Link
          to={link}
          component={({ title, disabled }) => (
            <Button
              label={title}
              size={"small"}
              disabled={disabled}
              btnStyle={"currentColor_inverted"}
            />
          )}
        />
      </div>
    </div>
  );
};
