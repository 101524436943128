import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import { Plus } from "react-feather";

import { classList } from "../../lib/helpers";
import { Section, SectionSettingsData, SectionWidth } from "../Section/Section";
import { BlockContent } from "../BlockContent/BlockContent";
import * as styles from "./Collapsables.module.scss";

export type CollapsablesData = {
  collapsablesItems: CollapsablesItemData[];
  sectionSettings: SectionSettingsData;
};

export type CollapsablesItemData = {
  content: any;
  title: string;
  _key: string;
};

export interface CollapsablesProps {
  data: CollapsablesData;
  language: string;
}

export interface CollapsablesItemProps {
  data: CollapsablesItemData;
  language: string;
}

export const Collapsables: React.FC<CollapsablesProps> = ({
  data,
  language,
}) => {
  return (
    <Section width={SectionWidth.NARROW} {...data.sectionSettings}>
      {data?.collapsablesItems.map((item) => {
        return (
          <CollapsablesItem data={item} language={language} key={item._key} />
        );
      })}
    </Section>
  );
};

const CollapsablesItem: React.FC<CollapsablesItemProps> = ({
  data: { title, content },
  language,
}) => {
  const [toggled, setToggled] = useState(false);
  const classes = classList(
    styles.collapsablesItem,
    toggled && styles.collapsablesItem_open
  );
  return (
    <div className={classes}>
      <button
        className={styles.collapsablesItem__title}
        onClick={() => setToggled(!toggled)}
      >
        <h3>{title}</h3>
        <i>
          <Plus className={styles.icon_plus} />
        </i>
      </button>
      <AnimateHeight duration={500} height={toggled ? "auto" : 0}>
        <div className={styles.collapsablesItem__content}>
          <BlockContent blocks={content} language={language} nested />
        </div>
      </AnimateHeight>
    </div>
  );
};
