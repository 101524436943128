import React from "react";
import { BlockContent } from "../BlockContent/BlockContent";

export type TableData = {
  tableRows: TableRowData[];
};

export type TableRowData = {
  tableCells: TableCellData[];
  _key: string;
};

export type TableCellData = {
  content: any;
  _key: string;
};

export interface TableProps {
  data: TableData;
  className?: string;
  language: string;
}

export const Table: React.FC<TableProps> = ({
  className,
  language,
  data: { tableRows },
}) => {
  return (
    <table>
      <tbody>
        {tableRows.map((row) => {
          return (
            <TableRow
              tableCells={row.tableCells}
              language={language}
              key={row._key}
            />
          );
        })}
      </tbody>
    </table>
  );
};

interface TableRowProps extends TableRowData {
  language: string;
}

const TableRow: React.FC<TableRowProps> = ({ tableCells, language }) => {
  return (
    <tr>
      {tableCells.map((cell) => {
        return (
          <td key={cell._key}>
            <BlockContent nested blocks={cell.content} language={language} />
          </td>
        );
      })}
    </tr>
  );
};
