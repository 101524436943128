import React from "react";

import { classList } from "../../lib/helpers";
import * as styles from "./Badge.module.scss";

export type BadgeData = {
  text?: string;
  backgroundColor?: string[];
};

export interface BadgeProps {
  data: BadgeData;
  className?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  className,
  data: { text, backgroundColor },
}) => {
  const classes = classList(
    styles.badge,
    backgroundColor && styles["badge_" + backgroundColor[0]],
    className && className
  );

  return <div className={classes}>{text}</div>;
};
