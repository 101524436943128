import React from "react";
import { BlockContent } from "../BlockContent/BlockContent";
import { Section, SectionSettingsData, SectionWidth } from "../Section/Section";
import * as styles from "./Box.module.scss";

export type BoxData = {
  content: any;
  sectionSettings: SectionSettingsData;
};

export interface BoxProps {
  data: BoxData;
  language: string;
}

export const Box: React.FC<BoxProps> = ({ data, language }) => {
  return (
    <Section width={SectionWidth.NARROW} {...data.sectionSettings}>
      <BlockContent
        blocks={data.content}
        language={language}
        nested
        className={styles.box}
      />
    </Section>
  );
};
