import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { Section, SectionWidth } from "../Section/Section";
import { BlockContent } from "../BlockContent/BlockContent";
import * as styles from "./PostList.module.scss";

export type PostData = {
  id: string;
  language: string;
  publishedAt: Date;
  title: string;
  description: string;
  _rawPostContent?: any;
  postContent?: any;
};

export interface PostListProps {
  language: string;
}

export function useGetPosts(language: string, limit: number = 0) {
  const {
    allSanityPost: { nodes },
  } = useStaticQuery(graphql`
    query {
      allSanityPost(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          id
          language
          publishedAt
          title
          description
          _rawPostContent(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  `);

  const postListItems: PostData[] = (nodes as PostData[]).filter(
    (post) => post.language === language
  );

  if (limit > 0) {
    return postListItems.slice(0, limit);
  }
  return postListItems;
}

export const PostList: React.FC<PostListProps> = ({ language }) => {
  const postListItems = useGetPosts(language);

  return (
    <Section width={SectionWidth.NARROW}>
      {postListItems.map((post) => {
        return <NewsPost data={post} key={post.id} />;
      })}
    </Section>
  );
};

export interface NewsPostProps {
  data: PostData;
}

export const NewsPost: React.FC<NewsPostProps> = ({
  data: { id, title, publishedAt, _rawPostContent, postContent, language },
}) => {
  return (
    <div className={styles.newsPost} key={id}>
      <div>
        <span className={styles.newsPost__header__date}>
          {formatPostDate(language, publishedAt)}
        </span>
        <h2>{title}</h2>
      </div>
      <BlockContent
        blocks={_rawPostContent || postContent}
        language={language}
        nested
      />
    </div>
  );
};

export function formatPostDate(language: string, date: Date) {
  return new Intl.DateTimeFormat(language, {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(new Date(date));
}
