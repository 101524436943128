import React from "react";
import { Badge, BadgeData } from "../Badge/Badge";

import { Grid, GridItem } from "../Grid/Grid";
import { MainImage, ImageData } from "../MainImage/MainImage";
import { Section, SectionSettingsData } from "../Section/Section";
import { BlockContent } from "../BlockContent/BlockContent";
import * as styles from "./PricesOverview.module.scss";

export type PricesOverviewItemData = {
  badge?: BadgeData;
  content: any;
  image: ImageData;
  _key?: string;
};

export type PricesOverviewData = {
  pricesOverviewItems: PricesOverviewItemData[];
  sectionSettings: SectionSettingsData;
};

export interface PricesOverviewProps {
  data: PricesOverviewData;
  language: string;
}

export interface PricesOverviewItemProps {
  data: PricesOverviewItemData;
  language: string;
}

export const PricesOverview: React.FC<PricesOverviewProps> = ({
  data,
  language,
}) => {
  const widthPct = Math.round(100 / data?.pricesOverviewItems?.length);
  return (
    <Section {...data.sectionSettings}>
      <Grid
        gutterSize="small"
        className={styles.pricesOverview}
        alignment={"top"}
      >
        {data?.pricesOverviewItems.map((item) => {
          return (
            <GridItem width={widthPct} key={item._key}>
              <PricesOverviewItem data={item} language={language} />
            </GridItem>
          );
        })}
      </Grid>
    </Section>
  );
};

export const PricesOverviewItem: React.FC<PricesOverviewItemProps> = ({
  data: { badge, content, image },
  language,
}) => {
  return (
    <div className={styles.pricesOverview__item}>
      {badge && (
        <Badge data={badge} className={styles.pricesOverview__item__badge} />
      )}
      <div className={styles.pricesOverview__item__image}>
        <div className={styles.pricesOverview__item__image__container}>
          <MainImage imageData={image} />
        </div>
      </div>
      <div className={styles.pricesOverview__item__description}>
        <BlockContent blocks={content} language={language} nested />
      </div>
    </div>
  );
};
