import React from "react";
import { PopoverMenu, PopoverMenuItem } from "../PopoverMenu/PopoverMenu";
import {
  supportedLanguages,
  getLanguageDataForCode,
  getBasePathForLanguage,
} from "../../../sanity/plugins/multilanguage";

import { Link as GatsbyLink } from "gatsby";
import * as styles from "./LanguageSwitch.module.scss";

export interface LanguageSwitchProps {
  availableLanguages?: string[];
  currentLanguage?: string;
  className?: string;
}

export const LanguageSwitch: React.FC<LanguageSwitchProps> = ({
  availableLanguages,
  currentLanguage,
  className,
}) => {
  const selectableLanguages = availableLanguages?.filter(
    (lang) => lang !== currentLanguage
  );
  return (
    <PopoverMenu
      triggerElement={
        <span className={styles.languageSwitchTrigger}>{currentLanguage}</span>
      }
      className={className && className}
    >
      {selectableLanguages?.map((lang, index) => {
        const langData = getLanguageDataForCode(supportedLanguages, lang);
        return (
          <GatsbyLink to={getBasePathForLanguage(lang)} key={index}>
            <PopoverMenuItem title={langData.title} />
          </GatsbyLink>
        );
      })}
    </PopoverMenu>
  );
};
