import React, { HTMLAttributes } from "react";
import { Link, LinkData } from "../Link/Link";
import { formatPostDate, useGetPosts, PostData } from "../PostList/PostList";
import { classList } from "../../lib/helpers";

import * as styles from "./NewsBox.module.scss";

export type NewsBoxData = {
  title?: string;
  numberOfPosts: number;
  link?: LinkData;
};

export interface NewsBoxProps extends HTMLAttributes<HTMLDivElement> {
  data: NewsBoxData;
  language: string;
  appearance?: "default" | "navigationPage";
}

export const NewsBox: React.FC<NewsBoxProps> = ({
  data,
  language,
  appearance = "default",
  ...props
}) => {
  const classes = classList(styles.newsBox, styles["newsBox_" + appearance]);
  const newsPosts = useGetPosts(language, data?.numberOfPosts);
  return (
    <div className={classes} {...props}>
      {data?.title && (
        <h2 className={styles.newsBox__headline}>{data?.title}</h2>
      )}
      <div className={styles.newsBox__content}>
        {newsPosts?.map((post) => {
          return <NewsBoxItem key={post.id} data={post} />;
        })}
      </div>
      {data?.link && <Link to={data?.link} className={styles.newsBox__more} />}
    </div>
  );
};

export interface NewsBoxItemProps {
  data: PostData;
}

const NewsBoxItem: React.FC<NewsBoxItemProps> = ({
  data: { publishedAt, title, description, language },
}) => {
  return (
    <div className={styles.newsBoxItem}>
      <div className={styles.newsBoxItem__header}>
        <span>{formatPostDate(language, publishedAt)}</span>
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </div>
  );
};
