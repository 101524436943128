// extracted by mini-css-extract-plugin
export var badge = "Badge-module--badge--3yFAN";
export var badge_lightGrey = "Badge-module--badge_lightGrey--fwQJK";
export var badge_black = "Badge-module--badge_black--kmgnv";
export var badge_casioBlue = "Badge-module--badge_casioBlue--jJvWz";
export var badge_blue = "Badge-module--badge_blue--GZuAe";
export var badge_blueDark = "Badge-module--badge_blueDark--BWHHg";
export var badge_cyan = "Badge-module--badge_cyan--QPrSi";
export var badge_cyanDark = "Badge-module--badge_cyanDark--FbOW9";
export var badge_green = "Badge-module--badge_green--0sHxK";
export var badge_greenDark = "Badge-module--badge_greenDark--tH5XX";
export var badge_yellow = "Badge-module--badge_yellow--pcw5Y";
export var badge_yellowDark = "Badge-module--badge_yellowDark--5QqYs";
export var badge_purple = "Badge-module--badge_purple--GEDaM";
export var badge_purpleDark = "Badge-module--badge_purpleDark--9oQSD";
export var badge_paleRed = "Badge-module--badge_paleRed--Z4SPZ";
export var badge_paleRedDark = "Badge-module--badge_paleRedDark--BFSnu";
export var badge_magenta = "Badge-module--badge_magenta--u71aX";
export var badge_magentaDark = "Badge-module--badge_magentaDark--0OFC1";
export var badge_orange = "Badge-module--badge_orange--Jn-5D";
export var badge_orangeDark = "Badge-module--badge_orangeDark--kcfHl";
export var badge_brown = "Badge-module--badge_brown--njLvG";
export var badge_brownDark = "Badge-module--badge_brownDark--Xfa6U";