import React from "react";
import { Grid, GridItem } from "../Grid/Grid";
import { Section, SectionSettingsData } from "../Section/Section";
import { BlockContent } from "../BlockContent/BlockContent";

export type ColumnsData = {
  leftContent: any;
  rightContent: any;
  alignment: string;
  orderOnMobile: string;
  ratio: number;
  sectionSettings: SectionSettingsData;
};

export interface ColumnsProps {
  data: ColumnsData;
  language: string;
}

export const Columns: React.FC<ColumnsProps> = ({ data, language }) => {
  const leftWidthPct = data?.ratio;
  const rightWidthPct = 100 - leftWidthPct;
  const flipMobileDirection =
    data?.orderOnMobile === "rowReverse" ? true : false;

  return (
    <Section {...data?.sectionSettings}>
      <Grid
        alignment={data?.alignment}
        flipMobileDirection={flipMobileDirection}
      >
        <GridItem width={leftWidthPct}>
          <BlockContent blocks={data?.leftContent} language={language} nested />
        </GridItem>
        <GridItem width={rightWidthPct}>
          <BlockContent
            blocks={data?.rightContent}
            language={language}
            nested
          />
        </GridItem>
      </Grid>
    </Section>
  );
};
