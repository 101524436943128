import { Link, LinkData } from "../Link/Link";
import React from "react";
import { Button } from "../Button/Button";
import { Section, SectionSpace, SectionWidth } from "../Section/Section";
import * as styles from "./AnnouncementBanner.module.scss";
import { MainImage } from "../MainImage/MainImage";

export type AnnouncementBannerData = {
  title?: string;
  subtitle?: string;
  logoImage?: any;
  link?: LinkData;
};

export interface AnnouncementBannerProps {
  data?: AnnouncementBannerData;
}

export const AnnouncementBanner: React.FC<AnnouncementBannerProps> = ({
  data,
}) => {
  return (
    <Section
      width={SectionWidth.FULL}
      spacingTop={SectionSpace.NONE}
      spacingBottom={SectionSpace.NONE}
      className={styles.announcementBannerSection}
    >
      <div className={styles.announcementBanner}>
        <div className={styles.announcementBanner__content}>
          <div>
            <div className={styles.announcementBanner__content__logo}>
              <MainImage
                className={styles.announcementBanner__content__logo__image}
                imageData={data?.logoImage}
              />
            </div>
            <div className={styles.announcementBanner__content__heading}>
              <p>{data?.title}</p>
              <p>{data?.subtitle}</p>
            </div>
          </div>
          <Link
            to={data?.link}
            component={(link) => <Button label={link.title} size={"small"} />}
          />
        </div>
      </div>
    </Section>
  );
};
