import "react-multi-carousel/lib/styles.css";

import React from "react";
import Carousel from "react-multi-carousel";

import * as styles from "./PricesSlider.module.scss";
import { MainImage, ImageData } from "../MainImage/MainImage";
import { Section, SectionSettingsData } from "../Section/Section";
import { Badge, BadgeData } from "../Badge/Badge";

export type PricesSliderItemData = {
  badge: BadgeData;
  title?: string;
  subtitle?: string;
  _key?: string;
  image?: ImageData;
};

export type PricesSliderData = {
  autoplay?: boolean;
  autoplaySpeed?: number;
  pricesSliderItems: PricesSliderItemData[];
  sectionSettings: SectionSettingsData;
};

export interface PricesSliderProps {
  data: PricesSliderData;
}

export interface PricesSliderItemProps {
  data: PricesSliderItemData;
}

export const PricesSlider: React.FC<PricesSliderProps> = ({
  data: {
    autoplay = true,
    autoplaySpeed = 3000,
    pricesSliderItems,
    sectionSettings,
  },
}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
    },
  };
  return (
    <Section {...sectionSettings}>
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={autoplay}
        autoPlaySpeed={autoplaySpeed}
      >
        {pricesSliderItems.map((item) => {
          return <PricesSliderItem data={item} key={item._key} />;
        })}
      </Carousel>
    </Section>
  );
};

export const PricesSliderItem: React.FC<PricesSliderItemProps> = ({
  data: { title, subtitle, image, badge },
}) => {
  return (
    <div className={styles.pricesSlider__item}>
      <Badge data={badge} />
      <div className={styles.pricesSlider__item__image}>
        <div className={styles.pricesSlider__item__image__container}>
          <MainImage imageData={image} />
        </div>
      </div>
      <div className={styles.pricesSlider__item__titleContainer}>
        <span className={styles.pricesSlider__item__titleContainer__title}>
          {title}
        </span>
        {subtitle && (
          <span className={styles.pricesSlider__item__titleContainer__subtitle}>
            {subtitle}
          </span>
        )}
      </div>
    </div>
  );
};
