import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { classList } from "../../lib/helpers";
import clientConfig from "../../../client-config";
import imageUrlBuilder from "@sanity/image-url";
import { getImageAsset } from "@sanity/asset-utils";
import { Link, LinkProps } from "../Link/Link";
import * as styles from "./MainImage.module.scss";

export type ImageData = {
  alt: string;
  asset: any;
  aspectRatio: string;
  maxWidth?: number;
  link: {
    page: {
      slug: {
        current: string;
      };
    };
    externalUrl: string;
  };
};

interface ImageProps {
  imageData?: ImageData;
  className?: string;
}

export const MainImage: React.FC<ImageProps> = ({ imageData, className }) => {
  if (!imageData || !imageData?.asset) return null;

  const classes = classList(className && className);
  const gatsbyImage = getGatsbyImageData(
    imageData,
    { aspectRatio: getAspectRatio(imageData?.aspectRatio) },
    clientConfig.sanity
  );

  const imageAsset = imageData?.asset?.extension
    ? imageData?.asset
    : imageData && getImageAsset(imageData.asset, clientConfig.sanity);

  const isSVG = imageAsset?.extension === "svg";

  const style: React.CSSProperties = {
    maxWidth: imageData?.maxWidth && imageData.maxWidth + "px",
  };

  if (isSVG) {
    const builder = imageUrlBuilder(clientConfig.sanity);
    const svgImage = builder.image(imageData);
    return (
      <LinkContainer to={imageData.link}>
        <MainImageContainer isRendered={imageData?.maxWidth as undefined}>
          <SVGImage
            className={classes}
            url={svgImage.url()!}
            alt={(svgImage.options?.source as any)?.alt}
            style={style}
          />
        </MainImageContainer>
      </LinkContainer>
    );
  } else {
    return (
      <LinkContainer to={imageData.link}>
        <MainImageContainer isRendered={imageData?.maxWidth as undefined}>
          <GatsbyImage
            className={classes}
            image={gatsbyImage!}
            alt={imageData?.alt || ""}
            style={style}
          />
        </MainImageContainer>
      </LinkContainer>
    );
  }
};

interface SVGImageProps {
  url?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const SVGImage: React.FC<SVGImageProps> = ({
  url,
  alt,
  className,
  style,
}) => {
  const classes = classList(styles.svgImage, className && className);
  return <img style={style} className={classes} src={url} alt={alt} />;
};

interface MainImageContainerProps {
  isRendered: boolean | undefined;
  children: React.ReactNode;
}

const MainImageContainer: React.FC<MainImageContainerProps> = ({
  children,
  isRendered,
}) => {
  return isRendered ? (
    <div className={styles.mainImageContainer}>{children}</div>
  ) : (
    <>{children}</>
  );
};

const LinkContainer = (props: LinkProps) => {
  return props.to ? <Link {...props} /> : <>{props.children}</>;
};

function getAspectRatio(aspectRatio: string | null): number {
  switch (aspectRatio) {
    case "source":
      return 0;
    case "standard":
      return 16 / 9;
    default:
      return 0;
  }
}
