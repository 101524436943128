import React from "react";

import IPadMockup from "../../assets/svg/ipad-mockup.svg";
import { classList } from "../../lib/helpers";
import { Link, LinkData } from "../Link/Link";
import { Button } from "../Button/Button";
import { MainImage } from "../MainImage/MainImage";
import * as styles from "./TabletScreen.module.scss";
import { Youtube } from "react-feather";
import { SectionSettingsData, SectionWidth } from "../Section/Section";
import { Section } from "../Section/Section";

export type TabletScreenData = {
  background: Array<any>;
  backgroundLink?: LinkData;
  bannerLogo: any;
  bannerLink: LinkData;
  sectionSettings: SectionSettingsData;
};

interface TabletScreenProps {
  data: TabletScreenData;
  language: string;
}

export const TabletScreen: React.FC<TabletScreenProps> = ({
  data,
  language,
}) => {
  return (
    <Section width={SectionWidth.NARROW} {...data?.sectionSettings}>
      <IpadFrame className={styles.ipad__frame}>
        <div className={styles.ipad__frame__container}>
          <div className={styles.ipad__frame__container__background}>
            <Link to={data?.backgroundLink!}>
              <ImageOrVideoBackground data={data?.background} />
            </Link>
          </div>
          <div className={styles.ipad__frame__container__card}>
            <MainImage imageData={data?.bannerLogo} />
            <Link
              to={data?.bannerLink}
              component={(link) => (
                <Button label={link.title} btnStyle={"outline_inverted"} />
              )}
            />
          </div>
        </div>
      </IpadFrame>
    </Section>
  );
};

interface ImageOrVideoBackgroundProps {
  data: Array<any>;
}

const ImageOrVideoBackground: React.FC<ImageOrVideoBackgroundProps> = ({
  data,
}) => {
  switch (data[0]?._type) {
    case "mainImage":
      return <MainImage imageData={data[0]} />;
    case "file":
      return (
        <>
          <Youtube />
          <video autoPlay={true} muted loop>
            <source src={data[0]?.asset?.url} type="video/mp4" />
          </video>
        </>
      );
    default:
      return <div>undefined</div>;
  }
};

interface IpadFrameProps {
  children: React.ReactNode;
  className?: string;
}

const IpadFrame: React.FC<IpadFrameProps> = ({ className, children }) => {
  const classes = classList(styles.ipadFrame, className && className);
  return (
    <div className={classes}>
      <IPadMockup className={styles.ipadFrame__mockup} />
      <div className={styles.ipadFrame__screen}>
        <div>{children}</div>
      </div>
    </div>
  );
};
