import React from "react";
import { MainImage } from "../MainImage/MainImage";
import { Section, SectionSettingsData, SectionWidth } from "../Section/Section";

export type ImageBlockData = {
  image: any;
  sectionSettings: SectionSettingsData;
};

export interface ImageBlock {
  data: ImageBlockData;
}

export const ImageBlock: React.FC<ImageBlock> = ({ data }) => {
  return (
    <Section width={SectionWidth.NARROW} {...data?.sectionSettings}>
      <MainImage imageData={data?.image} />
    </Section>
  );
};
