import React from "react";
import { Section, SectionSettingsData, SectionWidth } from "../Section/Section";

export type CustomCodeData = {
  htmlCode: {
    code: string;
    language: string;
  };
  sectionSettings: SectionSettingsData;
};

export interface CustomCodeProps {
  data: CustomCodeData;
  language: string;
}

export const CustomCode: React.FC<CustomCodeProps> = ({ data }) => {
  return (
    <Section width={SectionWidth.NARROW} {...data.sectionSettings}>
      <div dangerouslySetInnerHTML={{ __html: data.htmlCode.code }} />
    </Section>
  );
};
